import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import cx from 'classnames';

import { blocks } from 'components';
import { VIEW_PERMISSIONS_DICTIONARY } from 'const/menu';
import { useUsersApi } from 'hooks/api';

import './style.scss';

const HomeScreen = ({ routes, isLanck }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentUser } = useUsersApi();

  const className = cx('home-screen', {
    'home-screen_dashboard': pathname === '/dashboard'
        || pathname === '/',
    'home-screen_ds': pathname?.includes('/recipients')
        || pathname === '/recipients/stop-words'
        || pathname === '/media'
        || pathname === '/audit'
        || pathname === '/account'
        || pathname === '/conversations'
        || pathname === '/media/sender-ids'
        || pathname === '/media/content-templates'
        || pathname === '/media/dialogue-templates'
        || pathname === '/dashboard'
        || pathname?.includes('/recipients/import')
        || pathname === '/user-management'
        || pathname === '/media/whats-app-templates'
        || pathname === '/2fa-sms'
        || pathname === '/2fa-voice'
        || pathname === '/api-accesses'
        || pathname === '/finance'
        || pathname === '/edrs'
        || pathname === '/traffic-statistics'
        || pathname === '/voice-statistics'
        || pathname === '/services'
        || pathname?.includes('campaigns')
        || pathname?.includes('broadcasts')
        || pathname === '/',
  });

  useEffect(() => {
    const userRolesList = currentUser?.roles || [];
    const permissionViewKey = VIEW_PERMISSIONS_DICTIONARY[pathname];
    if (permissionViewKey && !userRolesList.includes(permissionViewKey)) {
      history.push('/');
    }
  }, [pathname, currentUser]);

  return (
    <>
      <blocks.SideMenu isLanck={isLanck} />
      <div className={className}>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </Switch>
      </div>
    </>
  );
};

export default HomeScreen;

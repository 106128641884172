import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ConstructorEditor as ConstructorEditorNew } from 'mkit-front-ds/dist/components/constructor';
import classNames from 'classnames';

import { useSendersApi, useRecipientsApi, useTemplatesApi } from 'hooks/api';
import { DropdownField, DropdownSpecifyField, TimePickerField } from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import { ConstructorEditor, SendersDropdown } from 'components/constructor';
import LoadingSpinner from 'components/LoadingSpinner';
import { convertObjectTimeToSeconds } from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import trashIcon from 'assets/icons/trash.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import disketteDarkIcon from 'assets/icons/diskette-dark.svg';

import './style.scss';

const initialSmsParams = {
  selectedType: 'smsText',
  message: '',
};

const SmsBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  withTemplates,
  senderId,
  withTools = true,
  readOnly = false,
  isNewDesign = false,
  deliveryCondition = 'DELIVERY_SUCCESS',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
  withShortLink,
  setShortLinks,
}) => {
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };

  const wrapperClass = classNames('sms-block__content-wrapper', {
    'sms-block__content-wrapper_new-design': isNewDesign,
    alwaysLightTheme: true,
  });

  const wrapperHeaderClass = classNames('sms-block__content-wrapper__header', {
    'sms-block__content-wrapper__header_new-design': isNewDesign,
  });

  const { t } = useTranslation();
  const {
    getSenders,
    senders,
    isPendingGetSenders,
  } = useSendersApi();
  const {
    getTemplates,
    templates,
    postCreateTemplate,
    deleteTemplate,
    patchUpdateTemplate,
    isPendingGetTemplates,
  } = useTemplatesApi();
  const { getAttributes, isPendingGetAttributes } = useRecipientsApi();
  const [sender, setSender] = useState(senderId || '');
  const [template, setTemplate] = useState({});
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [waitingTime, setWaitingTime] = useState(timeout);
  const [showError, setShowError] = useState(false);
  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };
  const [rcsParams, updateRcsParams] = useReducer(reducer, initialSmsParams);

  const rcsTemplates = useMemo(() => templates.filter(I => I.channel === 1).map(I => ({
    id: I.id,
    title: I.name,
  })), [templates]);
  const sendersOptions = useMemo(() => senders.filter(I => I.channel === 1 && I.status === 2)
    .map(I => ({
      id: I.senderId,
      title: I.displayName,
    })), [senders]);

  const onApply = () => {
    if (!sender && withTools) {
      setShowError(true);
    } else {
      setShowError(false);

      onChange({
        index,
        channelType: 1,
        contentPattern: rcsParams.message,
        deliveryCondition: waitFor,
        order: 0,
        senderId: sender,
        timeout: convertObjectTimeToSeconds(waitingTime),
      });
    }
  };
  const onSaveTemplate = () => {
    const templateBody = {
      name: template.value,
      channel: 1,
      content: JSON.stringify(rcsParams),
    };

    if (template.isCustom) {
      postCreateTemplate(templateBody)
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    } else {
      patchUpdateTemplate({
        ...templateBody,
        name: rcsTemplates.find(I => I.id === template.value).title,
        id: template.value,
      })
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    }
  };
  const onDeleteTemplate = () => {
    deleteTemplate(template.value)
      .then(() => {
        getTemplates({ limit: 100, offset: 0 });
      });
  };

  useEffect(() => {
    const selectedItem = templates.find(I => I.id === template.value);

    if (selectedItem) {
      updateRcsParams({
        isReset: true,
        ...JSON.parse(selectedItem.content),
      });
    }
  }, [template]);
  useEffect(() => {
    if (!isEmpty(template)) {
      const foundItem = rcsTemplates.find(I => I.title === template.value
        || I.id === template.value);

      if (foundItem) {
        setTemplate({ value: foundItem.id, isCustom: false });
      } else {
        setTemplate({});
      }
    }
  }, [rcsTemplates]);
  useEffect(() => {
    if (!rcsParams.message) {
      updateRcsParams({
        isReset: true,
        message: channel?.message || '',
        selectedType: 'smsText',
      });
    }
  }, [channel]);
  useEffect(() => {
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getTemplates({ limit: 100, offset: 0 });
    getAttributes({ variablesOnly: true });
  }, []);

  if (isPendingGetTemplates || isPendingGetSenders || isPendingGetAttributes) {
    return <LoadingSpinner fullSize />;
  }

  return (
    <div className="sms-block">
      <h2 className="sms-block__header">
        {t('SCREENS.BROADCASTS.SMS_MESSAGE_SETTINGS')}
      </h2>
      {
        (withTools || withTemplates) && (
          <div className="sms-block__tools">
            <span className="sms-block__label" style={{ minWidth: 'unset' }}>
              {t('SCREENS.BROADCASTS.TEMPLATE')}
            </span>
            <DropdownSpecifyField
              input={{
                value: template,
                onChange: setTemplate,
              }}
              placeholder={t('PLACEHOLDERS.ENTER_NAME')}
              items={rcsTemplates}
              style={{ width: 329 }}
              disabled={readOnly}
            />
            <IconButton
              icon={disketteDarkIcon}
              onClick={onSaveTemplate}
              isDisabled={isEmpty(template) || readOnly}
              filterType="white"
            />
            <IconButton
              icon={trashIcon}
              onClick={onDeleteTemplate}
              isDisabled={isEmpty(template) || template.isCustom || readOnly}
              filterType="white"
            />
          </div>
        )
      }
      <div className={wrapperClass}>
        <div className={wrapperHeaderClass}>
          {
            withTools && (
              <SendersDropdown
                values={sendersOptions}
                value={sender}
                onChange={setSender}
                showError={showError}
                disabled={readOnly}
                channel="sms"
                isNewDesign
              />
            )
          }
        </div>
        {isNewDesign ? (
          <ConstructorEditorNew
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            withoutSuggestions
            readOnly={readOnly}
            constructorType="sms"
            withShortLink={withShortLink}
            setShortLinks={setShortLinks}
          />
        ) : (
          <ConstructorEditor
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            withoutSuggestions
            readOnly={readOnly}
          />
        )}
      </div>
      {
        withTools && (
          <>
            <div className="sms-block__time-tools" style={{ marginTop: 24 }}>
              <span className="sms-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('SCREENS.BROADCASTS.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('SCREENS.BROADCASTS.SUBMIT_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="sms-block__time-tools">
              <span className="sms-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="sms-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default SmsBlock;

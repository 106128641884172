import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import routes from 'const/routes';
import { useUsersApi } from 'hooks/api';
import { locale } from 'helpers';
import { useTheme } from 'hooks';

const AppRoutes = ({ isLanck }) => {
  const { currentUser } = useUsersApi();
  const { i18n } = useTranslation();
  const { setDarkTheme } = useTheme();

  useEffect(() => {
    locale.setLanguage(currentUser.language);
    i18n.changeLanguage(currentUser.language);
    setDarkTheme(currentUser.darkTheme);
  }, [currentUser]);

  return (
    <Switch>
      {
        routes
          .map(route => (
            <Route
              key={route.path}
              path={route.path}
              render={props => <route.component {...props} routes={route.children} isLanck={isLanck} />}
            />
          ))
      }
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default AppRoutes;

import {
  RecipientsScreen,
  StopWordsScreen,
  FinancialDocumentsScreen,
  MediaScreen,
  HomeScreen,
  RatePlansScreen,
  EdrScreen,
  ReportsScreen,
  SenderIdsScreen,
  CampaignsScreen,
  DialogScreen,
  TemplatesScreen,
  AccountScreen,
  ScenariosScreen,
  TestServicesScreen,
  UserManagementScreen,
  AuditScreen,
  WhatsAppTemplatesScreen,
  NewDashboard,
  ImportScreen,
  DialogueTemplatesScreen,
  TwoFASMSScreen,
  TwoFAVoiceScreen,
  BroadcastScreen,
  ApiAccesses,
  TrafficStatisticsScreen,
  BroadcastsScreen,
  VoiceStatisticsScreen,
} from 'screens';

import { IS_DEV_HOST, IS_LANC_HOST } from './hosts';

let children = [
  {
    path: '/account',
    component: AccountScreen,
  },
  {
    path: '/dashboard',
    component: NewDashboard,
  },
  {
    path: '/recipients/import/:id?',
    component: ImportScreen,
  },
  {
    path: '/recipients/stop-words',
    component: StopWordsScreen,
  },
  {
    path: '/recipients/:initialTab?',
    component: RecipientsScreen,
  },
  {
    path: '/finance',
    component: FinancialDocumentsScreen,
  },
  {
    path: '/campaigns/:id',
    component: BroadcastScreen,
    exact: true,
  },
  {
    path: '/campaigns',
    component: CampaignsScreen,
  },
  {
    path: '/broadcasts/:id/:type',
    component: BroadcastScreen,
    exact: true,
  },
  {
    path: '/broadcasts/:initialTab?',
    component: BroadcastsScreen,
  },
  {
    path: '/media/sender-ids',
    component: SenderIdsScreen,
  },
  {
    path: '/media/content-templates',
    component: TemplatesScreen,
  },
  {
    path: '/media/whats-app-templates',
    component: WhatsAppTemplatesScreen,
  },
  {
    path: '/media/dialogue-templates',
    component: DialogueTemplatesScreen,
  },
  {
    path: '/media',
    component: MediaScreen,
  },
  {
    path: '/services',
    component: RatePlansScreen,
  },
  {
    path: '/edrs',
    component: EdrScreen,
  },
  {
    path: '/reports',
    component: ReportsScreen,
  },
  {
    path: '/conversations/chatbots',
    component: ScenariosScreen,
  },
  {
    path: '/conversations',
    component: DialogScreen,
  },
  {
    path: '/test-services',
    component: TestServicesScreen,
  },
  {
    path: '/user-management',
    component: UserManagementScreen,
  },
  {
    path: '/audit',
    component: AuditScreen,
  },
  {
    path: '/2fa-sms',
    component: TwoFASMSScreen,
  },
  {
    path: '/api-accesses',
    component: ApiAccesses,
  },
  {
    path: '/traffic-statistics',
    component: TrafficStatisticsScreen,
  },
  {
    path: '/voice-statistics',
    component: VoiceStatisticsScreen,
  },
  {
    path: '/',
    component: NewDashboard,
  },
];

if (IS_LANC_HOST || IS_DEV_HOST) {
  children = [
    {
      path: '/2fa-voice',
      component: TwoFAVoiceScreen,
    },
    ...children,
  ];
}

const routes = [
  {
    path: '/',
    component: HomeScreen,
    children,
  },
];

export default routes;

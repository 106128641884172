import React from 'react';
import VoiceStatistics from 'mkit-front-ds/dist/screens/VoiceStatistics';

import './style.scss';

const VoiceStatisticsScreen = () => (
  <div className="voice-statistics-screen-container">
    <VoiceStatistics />
  </div>
);

export default VoiceStatisticsScreen;

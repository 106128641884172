import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';
import { Point } from '@projectstorm/geometry';
import eachDeep from 'deepdash/eachDeep';
import { isEmpty } from 'lodash';

import { InfoModal, DeleteWarningModal } from 'components/modals';
import { CanvasWidget, uikit } from 'components';
import { EditNodeActionForm, EditNodeConditionForm } from 'components/forms';
import LoadingSpinner from 'components/LoadingSpinner';
import { useModalLogic } from 'hooks';
import { useCompaniesApi, useFlowResourceApi, useFlowsNewApi } from 'hooks/api';
import { base64 } from 'helpers';
import { CONDITION_NODE_TYPE, NODE_TYPE, STEP_TYPES } from 'const/scenario';
import { getTransformedRcs } from 'helpers/transforms';

import './style.scss';

const languages = {
  javascript: 2,
  python: 1,
};
const messageTypes = {
  fileMessage: 2,
  textMessage: 1,
  carousel: 6,
  richcardMessage: 5,
};
const getRcsMessageType = (body) => {
  const rcsMessageKeys = Object.keys(body?.RCSMessage ?? {});
  let rcsType = rcsMessageKeys?.length ? rcsMessageKeys[0] : '';

  if (rcsType === 'richcardMessage'
      && body.RCSMessage.richcardMessage.message.generalPurposeCardCarousel) {
    rcsType = 'carousel';
  }

  return messageTypes[rcsType];
};
const terminalStepTypes = [
  STEP_TYPES.HANDOVER_TO_HUMAN,
  STEP_TYPES.RESET_CHAT_AND_START_OVER,
  STEP_TYPES.JUMP_TO,
];

const DialogueScriptBlock = ({
  flowId,
  readOnlyAlways = false,
  currentNodeId,
  onClickNode,
  initialZoom,
  sendQuery = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedNode, setSelectedNode] = useState(null);
  const newNodePoint = useRef([]);
  const canvasWidgetRef = useRef(null);
  const nodeToDelete = useRef({});

  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const {
    flowResource,
    getFlowResource,
    isPendingGetFlowResource,
    lastUpdatedGetFlowResource,
    postFlowNode,
    putFlowNode,
    deleteFlowNode,
    postFlowLink,
    putFlowLink,
    deleteFlowLink,
    getValidators,
    isPendingGetValidators,
    lastUpdatedGetValidators,
    validators,
    postCloneNode,
  } = useFlowResourceApi(flowId);
  const {
    ownCompany,
    getOwnCompany,
  } = useCompaniesApi();
  const {
    getFlowById,
    getFlowsAll,
    flowsAll,
    isPendingGetFlowsAll,
  } = useFlowsNewApi();

  const isFirstActionNode = useMemo(() => selectedNode?.triggerType === 0, [selectedNode]);

  const closeViewDrawer = () => {
    newNodePoint.current = [];
    setSelectedNode(null);
  };
  const onCloseDrawer = () => {
    closeViewDrawer();

    if (selectedNode.id) {
      canvasWidgetRef.current.updateNode({
        entity: { selected: false },
        id: selectedNode.id,
      });
    }
  };
  const onConfirmDelete = () => {
    const id = selectedNode?.id || nodeToDelete.current.options.id;

    deleteFlowNode({
      id,
      successCallback: () => {
        canvasWidgetRef.current.removeNode({ id });
        closeModal();
        closeViewDrawer();
      },
    });
  };
  const checkOtherNodesTriggerType = (triggerType, nodeId) => {
    const foundNode = canvasWidgetRef.current.getEngine().getModel().getNodes()
      .find(node => node.entity.triggerType === triggerType && node.options.id !== nodeId);

    if (foundNode) {
      return putFlowNode({
        id: foundNode.getID(),
        body: {
          ...foundNode.entity,
          triggerType: null,
          meta: {
            position: foundNode.getPosition(),
          },
        },
        successCallback: (response) => {
          const node = canvasWidgetRef.current.getEngine().getModel().getNode(foundNode.getID());
          node.addInPort('in-2');
          canvasWidgetRef.current.updateNode({
            entity: response,
            id: response.id,
          });
        },
      });
    }

    return null;
  };
  const updateInPorts = (triggerType, nodeType) => {
    // если шаг стартовый, становится обычным
    if (selectedNode.triggerType !== 'null' && triggerType === 'null') {
      const node = canvasWidgetRef.current.getEngine().getModel().getNode(selectedNode.id);
      node.addInPort('in-2');
    } else if (selectedNode.triggerType === 'null' && triggerType !== 'null') {
      // если шаг обычный, становится стартовым
      const node = canvasWidgetRef.current.getEngine().getModel().getNode(selectedNode.id);
      const targetPort = node.getPort('in-1');
      Object.values(node.getPort('in-2').getLinks()).forEach((link) => {
        link.setTargetPort(targetPort);
      });
      node.removePort(node.getPort('in-2'));
    }
    // если предыдущий тип ноды не должен был иметь исходящего порта, а новый должен - создаем его
    if (terminalStepTypes.includes(selectedNode.type) && !terminalStepTypes.includes(nodeType)) {
      postFlowLink({
        body: {
          flowId,
          fromNodeId: selectedNode.id,
        },
        successCallback: (responseLink) => {
          canvasWidgetRef.current.addOutPort({
            nodeId: selectedNode.id,
            id: responseLink.id,
          });
        },
      });
    }
    // зеркалка условия выше
    if (!terminalStepTypes.includes(selectedNode.type) && terminalStepTypes.includes(nodeType)) {
      const node = canvasWidgetRef.current.getEngine().getModel().getNode(selectedNode.id);
      const port = node.getOutPorts()[0];

      node.removePort(port);
    }
  };
  const handleUpdateLink = ({ id, ...body }) => {
    putFlowLink({
      id,
      body: {
        flowId,
        ...body,
      },
    });
  };
  const onSubmitEditNodeCondition = async (
    formData,
    formApi,
    constructorData = null,
    paramSelectedNode = {},
  ) => {
    const body = {
      flowId,
      label: formData.label,
      nodeClass: formData.nodeClass,
      type: formData.type,
      triggerType: formData.triggerType !== 'null' ? formData.triggerType : null,
      userResponses: formData.userResponses,
      conditions: formData.conditions,
    };

    const currentSelectedNode = isEmpty(paramSelectedNode) ? selectedNode : paramSelectedNode;

    if (formData.triggerType !== 'null') {
      await checkOtherNodesTriggerType(formData.triggerType, currentSelectedNode.id);
    }

    if (constructorData?.nodeClass || currentSelectedNode.isNewNode) {
      await postFlowNode({
        id: constructorData?.id || currentSelectedNode.id,
        body: {
          ...body,
          meta: {
            position: newNodePoint.current,
          },
        },
      }).then((response) => {
        if (!response?.body?.id) {
          return;
        }

        canvasWidgetRef.current.addNode({
          type: constructorData?.nodeClass || currentSelectedNode?.nodeClass,
          point: newNodePoint.current,
          entity: body,
          id: response.body.id,
          withoutTopPort: formData.triggerType !== 'null',
        });

        let conditions = [];
        switch (formData.type) {
          case CONDITION_NODE_TYPE.CONDITION: {
            conditions = formData.conditions.map(item => [item]);
            break;
          }
          case CONDITION_NODE_TYPE.USER_RESPONSE: {
            conditions = formData.userResponses;
            break;
          }
          default: break;
        }
        // теперь вместо undefined в кондичиони добавляю
        // все значение для елсе и ее тип вибран как 5-ое
        conditions.push([{
          conditionType: 5,
          condition: formData.elseLabel || '',
          reportField: formData.reportField,
        }]);
        // добавил лайбл и репорт филд для флов нодов
        const queries = conditions.map(item => postFlowLink({
          body: {
            flowId,
            fromNodeId: response.body.id,
            condition: item,
            label: item ? item[0].label : '',
            reportField: item ? item[0].reportField || undefined : undefined,
          },
        }));

        // eslint-disable-next-line consistent-return
        return Promise.all(queries).then((values) => {
          values.forEach((responseQ) => {
            canvasWidgetRef.current.addOutPort({
              nodeId: response.body.id,
              id: responseQ.body.id,
              condition: responseQ.body.condition,
              isElse: !responseQ.body.condition,
              withBottomCircle: !responseQ.body.condition,
            });
          });
          canvasWidgetRef.current.repaintCanvas();

          closeViewDrawer();
          setTimeout(formApi.reset);
        }).then(() => {
          // тут по конструктору акчион нода создается кондичион нода по дате конструктора
          if (constructorData && constructorData?.flowNodeId) {
            const link = {
              id: constructorData.flowNodeId.id,
              condition: null,
              isTerminal: false,
              fromNodeId: constructorData.flowNodeId.fromNodeId, // flowNodeId
              toNodeId: response.body.id, // toNodeId
              meta: {
                portName: 'in-1',
              },
            };
            handleUpdateLink(link);
            const canvasModel = canvasWidgetRef.current
              .getEngine().getModel();
            const sourcePort = canvasModel.getNode(link.fromNodeId).addOutPort({
              id: link.id,
              condition: link.condition,
              isFinal: link.isTerminal,
              isElse: !link.condition,
              withBottomCircle: !link.condition,
              prevState: JSON.stringify({
                id: link.id,
                fromNodeId: link.fromNodeId,
                toNodeId: link.toNodeId,
                condition: link.condition,
                isFinal: link.isTerminal,
              }),
            });
            const targetNode = canvasModel.getNode(response.body.id);
            const newLink = sourcePort.link(targetNode.getPort('in-1'), { condition: null });
            canvasModel.addLink(newLink);
            canvasWidgetRef.current.repaintCanvas();
          }
        });
      });
    } else {
      updateInPorts(formData.triggerType);

      await putFlowNode({
        id: currentSelectedNode.id,
        body: {
          ...body,
          meta: {
            position: formData.position,
          },
        },
      }).then((response) => {
        if (!response?.body?.id) {
          return;
        }

        let prevCondition = [];
        switch (currentSelectedNode.type) {
          case CONDITION_NODE_TYPE.CONDITION: {
            prevCondition = currentSelectedNode.conditions.map(item => [item]);
            break;
          }
          case CONDITION_NODE_TYPE.USER_RESPONSE: {
            prevCondition = currentSelectedNode.userResponses;
            break;
          }
          default: break;
        }

        let nextCondition = [];
        switch (formData.type) {
          case CONDITION_NODE_TYPE.CONDITION: {
            nextCondition = formData.conditions.map(item => [item]);
            break;
          }
          case CONDITION_NODE_TYPE.USER_RESPONSE: {
            nextCondition = formData.userResponses?.filter(el => el[0].conditionType !== 5 && el);
            break;
          }
          default: break;
        }

        const nodeOutPorts = [...canvasWidgetRef.current.getNodeOutPorts({ id: currentSelectedNode.id })];
        const nodeOutPortsConditions = nodeOutPorts.map(el => ({
          condition: el.options.condition,
          id: el.options.id,
        }));
        const newConditions = [];
        // Логика для удаления добавления или
        // редоктирования флов линко вместо индексов
        // исползуют идентификаторы созданние для них
        nextCondition.forEach(async (el) => {
          const elInPrevConditions = prevCondition.find(item => item[0].id === el[0].id);
          prevCondition = prevCondition.filter(item => item[0].id !== el[0].id);
          if (elInPrevConditions) {
            const elFlowId = nodeOutPortsConditions
              .find(item => item.condition[0].id === el[0].id);
            if (JSON.stringify(el) !== JSON.stringify(elInPrevConditions)) {
              await canvasWidgetRef.current.updatePort({
                nodeId: currentSelectedNode.id,
                id: elFlowId.id,
                condition: el,
                label: el[0].label || '',
                reportField: el[0]?.reportField,
              });
            }
          } else {
            newConditions.push(el);
          }
        });

        let elseLabel;
        const elsePort = canvasWidgetRef.current.getEngine()
          .getModel().getNode(currentSelectedNode.id).getElsePort();
        let isElseNodeRemoved = false;
        if (currentSelectedNode.elseLabelIndex >= 0) {
          elseLabel = [{
            conditionType: 5,
            condition: formData.elseLabel || '',
            reportField: formData.reportField,
          }];
          if (currentSelectedNode.elseLabelIndex <= nextCondition.length - 1) {
            isElseNodeRemoved = true;
            canvasWidgetRef.current.removePort({
              nodeId: currentSelectedNode.id,
              id: nodeOutPorts[currentSelectedNode.elseLabelIndex].getID(),
            });
          } else if (currentSelectedNode.elseLabel !== formData.elseLabel
              || currentSelectedNode.reportField !== formData.reportField) {
            canvasWidgetRef.current.updatePort({
              nodeId: currentSelectedNode.id,
              id: nodeOutPorts[currentSelectedNode.elseLabelIndex].getID(),
              condition: elseLabel,
              reportField: formData.reportField,
            });
          }
        }
        if (prevCondition.length) {
          prevCondition.forEach(async (el) => {
            const elFlowId = nodeOutPortsConditions
              .find(item => item.condition[0].id === el[0].id);
            await canvasWidgetRef.current.removePort({
              nodeId: currentSelectedNode.id,
              id: elFlowId.id,
            });
          });
        }

        if (elsePort || currentSelectedNode.elseLabelIndex < 0) {
          isElseNodeRemoved = true;
        }
        if (isElseNodeRemoved) {
          newConditions.push([{
            conditionType: 5,
            condition: formData.elseLabel || '',
            reportField: formData.reportField,
          }]);
        }

        if (newConditions.length) {
          const queries = newConditions.map(condition => postFlowLink({
            body: {
              flowId,
              fromNodeId: currentSelectedNode.id,
              condition,
              label: condition[0].label || '',
              reportField: condition[0].reportField,
            },
          }));

          // eslint-disable-next-line consistent-return
          return Promise.all(queries).then((values) => {
            values.forEach((responseQ) => {
              canvasWidgetRef.current.addOutPort({
                nodeId: currentSelectedNode.id,
                id: responseQ.body.id,
                condition: responseQ.body.condition,
              });
            });

            canvasWidgetRef.current.updateNode({
              entity: {
                ...body,
                selected: false,
              },
              id: currentSelectedNode.id,
            });
            canvasWidgetRef.current.repaintCanvas();

            closeViewDrawer();
            setTimeout(formApi.reset);
          });
        }

        closeViewDrawer();
        setTimeout(formApi.reset);
        canvasWidgetRef.current.updateNode({
          entity: {
            ...body,
            selected: false,
          },
          id: currentSelectedNode.id,
        });
        canvasWidgetRef.current.repaintCanvas();
      });
    }
    getFlowById({ id: flowId });
    // if (!isEmpty(paramSelectedNode)) {
    //   getFlowsAll();
    //   getFlowResource({ id: flowId });
    //   canvasWidgetRef.current.repaintCanvas();
    // }
  };
  const getAllWhatsAppSuggestions = ({
    message,
    generateId = true,
    splitByGroups = true,
  }) => {
    const repliesList = message?.map(el => ({
      condition: el.reply.title,
      conditionType: 1,
      ...generateId && { id: shortid.generate() },
    }));
    if (splitByGroups) {
      return repliesList.map(I => [I]);
    }
    return repliesList;
  };
  const getAllRCSSuggestions = ({
    message,
    generateId = true,
    splitByGroups = true,
  }) => {
    let insideList = [];
    let outsideList = [];
    if (message.richcardMessage) {
      const isCarousel = !!message.richcardMessage.message?.generalPurposeCardCarousel;
      if (isCarousel) {
        const carousel = message.richcardMessage.message.generalPurposeCardCarousel.content;
        const cardSuggestions = carousel.flatMap(card => card.suggestions);
        insideList = cardSuggestions?.filter(el => el?.reply).map(sItem => ({
          condition: sItem?.reply?.displayText,
          conditionType: 1,
          ...generateId && { id: shortid.generate() },
        }));
      } else {
        insideList = message.richcardMessage.message
          ?.generalPurposeCard?.content?.suggestions?.filter(el => el?.reply).map(el => ({
            condition: el?.reply?.displayText,
            conditionType: 1,
            ...generateId && { id: shortid.generate() },
          }));
      }
    }
    if (message.suggestedChipList) {
      outsideList = message.suggestedChipList?.suggestions
        ?.filter(el => el.reply).map(el => ({
          condition: el.reply.displayText,
          conditionType: 1,
          ...generateId && { id: shortid.generate() },
        }));
    }
    if (splitByGroups) {
      insideList = insideList?.map(I => [I]);
      outsideList = outsideList?.map(I => [I]);
    }
    return [
      ...(Array.isArray(insideList) ? insideList : []),
      ...(Array.isArray(outsideList) ? outsideList : []),
    ];
  };

  // function mergeArrays(conditions, newSuggestions, oldSuggestions) {
  //   let initial = [...conditions];
  //   // если элемента больше нет, то удаляем его с линков
  //   oldSuggestions.forEach((oI) => {
  //     const isOldExist = !!newSuggestions.find(nI => nI.condition === oI.condition);
  //     if (!isOldExist) {
  //       initial = initial.map(sA => sA.filter(o => !Object.values(o).some(v => v === oI.condition)));
  //     }
  //   });
  //   // если добавлен новый элемент, то создаём новый линк в новой группе
  //   newSuggestions.forEach((nI) => {
  //     const isNewExist = !!oldSuggestions.find(oI => oI.condition === nI.condition);
  //     // исключаем возможность дублирования
  //     const isAlreadyExist = initial.some(sA => sA.some(o => o.condition === nI.condition));
  //     if (!isNewExist && !isAlreadyExist) {
  //       initial.push([{ label: '', ...nI, id: shortid.generate() }]);
  //     }
  //   });
  //   initial = initial.map(sA => sA.filter(iA => iA.conditionType !== 5));
  //   initial = initial.filter(sA => sA.length !== 0);
  //   return initial;
  // }

  const handleUpdateNode = ({ id, ...body }) => {
    putFlowNode({
      id,
      body: {
        flowId,
        ...body,
      },
    });
  };

  const prepareNode = (node) => {
    let selectedNodeItem = node;
    if (node?.nodeClass === NODE_TYPE.CONDITION) {
      if (node.type === 1) {
        const elseLabel = node.userResponses?.find(el => el[0].conditionType === 5 || !el);
        const elseLabelIndex = node.userResponses?.findIndex(el =>
          el[0].conditionType === 5 || !el);
        selectedNodeItem = {
          ...node,
          elseLabel: elseLabel ? elseLabel[0]?.condition : '',
          reportField: elseLabel ? elseLabel[0].reportField || undefined : undefined,
          elseLabelIndex,
          conditions: node.conditions?.filter(el => el) || [],
          userResponses: node.userResponses?.filter(el => el[0].conditionType !== 5 && el),
        };
      } else {
        const elseLabel = node.conditions?.find(el => el.conditionType === 5 || !el);
        const elseLabelIndex = node.conditions?.findIndex(el => el.conditionType === 5 || !el);
        const conditions = node.conditions?.filter(el => el.conditionType !== 5 && el);
        selectedNodeItem = {
          ...node,
          elseLabel: elseLabel ? elseLabel.condition : '',
          reportField: elseLabel ? elseLabel.reportField || undefined : undefined,
          conditions: conditions && conditions.some(el => !el || typeof el.condition !== 'string')
            ? conditions.filter(el => !!el).map(el => ({ condition: el })) : conditions,
          elseLabelIndex,
          userResponses: node.userResponses?.filter(el => el) || [],
        };
      }
    }
    return selectedNodeItem;
  };

  const handleClickNode = useCallback((node) => {
    if (readOnlyAlways) {
      onClickNode({ node, widget: canvasWidgetRef });
      return;
    }
    // Раньше для поля else мы просто в кодничионы добавляли undefined
    // а сейчас елсе имеет свой лайбл и репорт филд по этому перед
    // отправкой в бэк я запушиваю значения для елсе в кондичиони
    // а перед откритием формы беру значения для елсе из кондичионов и разделяю ее
    // то же сомое с респонс групами и кондичионами раньше
    // у них било только значение а сейчас респонс груп и лайбл
    const selectedNodeItem = prepareNode(node);
    setSelectedNode(selectedNodeItem);
  }, [onClickNode]);
  const onSubmitEditNodeAction = async (formData, formApi) => {
    const rcsMessage = formData.type === STEP_TYPES.ASK_QUESTION
      ? formData.questionContent?.RCSMessage
      : formData.content?.RCSMessage;
    const whatsAppMessage = formData?.content?.interactive?.action?.buttons
        || formData?.questionContent?.interactive?.action?.buttons;
    // добавил репорт филд для акчион нодов
    const body = {
      flowId,
      label: formData.label,
      nodeClass: formData.nodeClass,
      type: formData.type,
      triggerType: formData.triggerType !== 'null' ? formData.triggerType : null,
      failureAction: formData.failureAction !== 'null' ? formData.failureAction : null,
      timeout: formData.timeout ? +formData.timeout : undefined,
      actionCondition: formData.actionCondition,
      reportField: formData.reportField,
    };

    const conditionPoint = new Point(newNodePoint.current.x + 450, newNodePoint.current.y);
    const shouldCreateCondition = formData.createCondition
      && (formData.type === STEP_TYPES.STATIC_MESSAGE || formData.type === STEP_TYPES.ASK_QUESTION) && selectedNode.isNewNode;
    const flowNodeId = {};

    if (formData.triggerType !== 'null') {
      await checkOtherNodesTriggerType(formData.triggerType, selectedNode.id);
    }

    switch (formData.type) {
      case STEP_TYPES.OPT_OUT_USER: {
        body.settings = {
          wholeChannel: formData.wholeChannel,
        };
        break;
      }
      case STEP_TYPES.STATIC_MESSAGE: {
        if (formData.content) {
          body.settings = {
            message: {
              content: formData.content,
              messageType: getRcsMessageType(formData.content),
            },
          };

          if (formData.isTypingDelay) {
            body.settings.message.isTypingDelay = +formData.isTypingDelay;
          }
        }
        break;
      }
      case STEP_TYPES.DYNAMIC_MESSAGE: {
        body.settings = {
          message: {
            messageType: formData.messageType,
          },
        };

        if (formData.isTypingDelay) {
          body.settings.message.isTypingDelay = +formData.isTypingDelay;
        }

        if (formData.executeCodeDynamicMessage) {
          body.settings.message.code = {
            language: languages[formData.executeCodeDynamicMessage.language],
          };

          if (formData.executeCodeDynamicMessage.code) {
            try {
              // eslint-disable-next-line max-len
              body.settings.message.code.code = base64.encode(formData.executeCodeDynamicMessage.code);
            } catch (err) {
              console.error(err);
            }
          }
        }
        break;
      }
      case STEP_TYPES.ASK_QUESTION: {
        // для нодов типа задать вопрос добавил флаг showInReport
        body.settings = {
          threadVariable: formData.threadVariable,
          retryCount: formData.retryCount === 'null' ? null : formData.retryCount,
          validatorId: formData.validatorId,
          showInReport: formData.showInReport,
        };

        const foundValidator = validators.find(validator => validator.id === formData.validatorId);
        if (foundValidator) {
          body.settings.validatorName = foundValidator.name;
        }

        if (formData.questionContent) {
          body.settings.message = {
            messageType: getRcsMessageType(formData.questionContent),
            content: formData.questionContent,
          };
          if (formData.delayQuestion) {
            body.settings.message.isTypingDelay = +formData.delayQuestion;
          }
        }

        if (formData.retryContent) {
          body.settings.retryMessage = {
            messageType: getRcsMessageType(formData.retryContent),
            content: formData.retryContent,
          };

          if (formData.delayRetry) {
            body.settings.retryMessage.isTypingDelay = +formData.delayRetry;
          }
        }
        break;
      }
      case STEP_TYPES.EXECUTE_CODE: {
        body.settings = {
          // outParameters: formData.outParameters,
          // inParameters: formData.inParameters,
        };

        if (formData.executeCode) {
          body.settings.code = {
            language: languages[formData.executeCode.language],
          };

          if (formData.executeCode.code) {
            try {
              body.settings.code.code = base64.encode(formData.executeCode.code);
            } catch (err) {
              console.error(err);
            }
          }
        }
        break;
      }
      case STEP_TYPES.JUMP_TO: {
        body.settings = {
          passContext: !!formData.passContext,
          nodeId: formData.nodeId === 'null' ? null : formData.nodeId,
          flowId: formData.flowId === 'null' ? null : formData.flowId,
        };
        break;
      }
      default: break;
    }

    if (selectedNode.isNewNode) {
      await postFlowNode({
        id: selectedNode.id,
        body: {
          ...body,
          meta: {
            position: newNodePoint.current,
          },
        },
      }).then((response) => {
        if (!response?.body?.id) {
          return;
        }
        flowNodeId.fromNodeId = response.body.id;
        canvasWidgetRef.current.addNode({
          type: selectedNode?.nodeClass,
          point: newNodePoint.current,
          entity: body,
          id: response.body.id,
          withoutTopPort: formData.triggerType !== 'null',
        });
        if (!terminalStepTypes.includes(formData.type)) {
          // eslint-disable-next-line consistent-return
          return postFlowLink({
            body: {
              flowId,
              fromNodeId: response.body.id,
              isTerminal: terminalStepTypes.includes(formData.type),
            },
            successCallback: (responseLink) => {
              closeViewDrawer();
              setTimeout(formApi.reset);
              flowNodeId.id = responseLink.id;
              canvasWidgetRef.current.addOutPort({
                nodeId: response.body.id,
                id: responseLink.id,
                isFinal: terminalStepTypes.includes(formData.type),
              });
              canvasWidgetRef.current.repaintCanvas();
            },
          });
        }
        // eslint-disable-next-line consistent-return
        return closeViewDrawer();
      });
    } else {
      // const newSuggestionsList = getAllRCSSuggestions({
      //   message: rcsMessage,
      //   generateId: false,
      //   splitByGroups: false,
      // });
      // const oldSuggestionsList = getAllRCSSuggestions({
      //   message: selectedNode?.message?.content?.RCSMessage,
      //   generateId: false,
      //   splitByGroups: false,
      // });
      // const connectLink = flowResource.links.find(I => I.fromNodeId === formData.id);
      // if (connectLink) {
      //   const connectedNode = flowResource.nodes.find(I => I.id === connectLink.toNodeId && I.nodeClass === 1);
      //   if (connectedNode) {
      //     const targets = flowResource.links.filter(I => I.fromNodeId === connectedNode.id);
      //     const currentConditions = targets.reduce((acc, item) => {
      //       if (item.condition) {
      //         acc.push(item.condition);
      //       }
      //       return acc;
      //     }, []);
      //     if (currentConditions) {
      //       const result = mergeArrays(currentConditions, newSuggestionsList, oldSuggestionsList);
      //       const elseLabelIndex = currentConditions?.findIndex(el =>
      //         el[0].conditionType === 5 || !el);
      //       const conditionNodeData = {
      //         ...connectedNode,
      //         flowId: connectedNode.flowId,
      //         label: connectedNode.label,
      //         nodeClass: connectedNode.nodeClass,
      //         type: connectedNode.type,
      //         triggerType: connectedNode.triggerType,
      //         conditions: [],
      //         meta: connectedNode.meta,
      //         userResponses: result,
      //         position: connectedNode.meta?.position,
      //       };
      //       newNodePoint.current = conditionPoint;
      //       const preparedNode = prepareNode(connectedNode);
      //       const targetNode = canvasWidgetRef.current
      //         .getEngine()
      //         .getModel()
      //         .getNodes()
      //         .find(n => n.entity.id === connectedNode.id);
      //       const conditions = targetNode
      //         .getOutPorts()
      //         .map(p => p.getOptions().condition);
      //       onSubmitEditNodeCondition(conditionNodeData, formApi, {
      //         triggerType: 1,
      //       }, {
      //         ...preparedNode,
      //         elseLabelIndex,
      //         userResponses: conditions,
      //       });
      //     }
      //   }
      // }
      updateInPorts(formData.triggerType, formData.type);

      await putFlowNode({
        id: selectedNode.id,
        body: {
          ...body,
          meta: {
            position: formData.position,
          },
        },
        successCallback: () => {},
      }).then(() => {
        // todo test, return in successCallback
        closeViewDrawer();
        setTimeout(formApi.reset);
        canvasWidgetRef.current.updateNode({
          entity: {
            ...body,
            selected: false,
          },
          id: selectedNode.id,
        });
        canvasWidgetRef.current.repaintCanvas();
      });
    }
    if (shouldCreateCondition && (rcsMessage || whatsAppMessage)) {
      let userResponses = [];
      const isAskQuestionNode = formData.type === STEP_TYPES.ASK_QUESTION;
      if (rcsMessage) {
        userResponses = getAllRCSSuggestions({
          message: rcsMessage,
          splitByGroups: !isAskQuestionNode,
        });
      }
      if (whatsAppMessage) {
        userResponses = getAllWhatsAppSuggestions({
          message: whatsAppMessage,
          splitByGroups: !isAskQuestionNode,
        });
      }
      if (userResponses.length) {
        let conditionNodeData = {
          conditions: [],
          isNewNode: true,
          label: '',
          nodeClass: 1,
          triggerType: 'null',
        };
        if (isAskQuestionNode) {
          conditionNodeData = {
            ...conditionNodeData,
            type: 2,
            conditions: userResponses,
          };
        } else {
          conditionNodeData = {
            ...conditionNodeData,
            type: 1,
            userResponses,
          };
        }
        newNodePoint.current = conditionPoint;
        onSubmitEditNodeCondition(conditionNodeData, formApi, {
          isNewNode: true,
          triggerType: 1,
          nodeClass: 1,
          flowNodeId,
        });
      }
    }
    getFlowById({ id: flowId });
    // getFlowsAll();
    // getFlowResource({ id: flowId });
    // canvasWidgetRef.current.repaintCanvas();
  };

  const addNewNode = (type, point) => {
    const isFirstNode = !canvasWidgetRef.current.getEngine().getModel().getNodes()?.length;

    switch (type) {
      case NODE_TYPE.CONDITION: {
        const newNode = {
          isNewNode: true,
          triggerType: isFirstNode ? 0 : 'null',
          nodeClass: type,
        };

        newNodePoint.current = point;
        setSelectedNode(newNode);
        break;
      }
      case NODE_TYPE.ACTION: {
        const newNode = {
          isNewNode: true,
          triggerType: isFirstNode ? 0 : 'null',
          nodeClass: type,
          createCondition: true,
          type: STEP_TYPES.STATIC_MESSAGE,
        };

        newNodePoint.current = point;
        setSelectedNode(newNode);
        break;
      }
      default: break;
    }
    getFlowById({ id: flowId });
  };
  const nodeRemoved = () => {
    // в параметры приходит нода, в случае если будем пытаться восстановить неожидаемо удаленную ноду -
    // код для восстановления писать здесь
  };
  const deleteNode = (node) => {
    nodeToDelete.current = node;
    openModal('delete');
  };
  const nodeCloned = ({ id, successCallback }) => {
    postCloneNode({ id, successCallback });
    getFlowById({ id: flowId });
  };
  const handleRemoveLink = ({ id }) => {
    deleteFlowLink({ id });
  };

  useEffect(() => {
    getFlowResource({ id: flowId });
    getFlowById({
      id: flowId,
      successCallback: ({ status }) => {
        if (status === 1 && ownCompany?.isModerated) {
          openModal('info');
        }
      },
    });

    if (!validators.length) {
      getValidators();
    }
  }, [flowId, ownCompany]);

  // берем дефолтные значения для репорт филдов для каждого сценария они отдельные
  const reportFields = useState([...new Set(flowResource
    ?.reportFields?.filter(el => el))].map(el => ({
    value: el,
    label: el,
  })));

  useEffect(() => {
    const allReportFields = [];
    // find all nested report fields in scenario
    eachDeep(flowResource, (value, key) => {
      if (key === 'reportField' && value) {
        allReportFields.push(value);
      }
    });
    const contructorReportFields = flowResource?.nodes?.filter(el => el?.settings?.message?.content)
      .map((el) => {
        const channel = getTransformedRcs({ contentPattern: el?.settings?.message?.content });
        const channelInsideReportFields = channel?.suggestionsInside
          ?.map(elem => elem.reportField) || [];
        const channelOutsideReportFields = channel?.suggestionsOutside
          ?.map(elem => elem.reportField) || [];
        return [...channelInsideReportFields, ...channelOutsideReportFields];
      }).flat(2) || [];
    const flowReportFields = flowResource?.reportFields || [];
    reportFields[1]([...new Set([...flowReportFields, ...contructorReportFields, ...allReportFields])]
      .filter(el => el).map(el => ({
        value: el,
        label: el,
      })));
  }, [flowResource]);
  const initialValues = useMemo(() => (flowResource ? (
    {
      ...flowResource,
      links: flowResource.links.map(item => ({
        ...item,
        condition: item.condition ? item.condition.map((el) => {
          if (el && !el.id && !el.condition && typeof el === 'string') {
            return {
              condition: el,
              conditionType: 1,
              id: shortid.generate(),
            };
          }
          if (el && !el.id && !el.condition) {
            return {
              ...el,
              id: shortid.generate(),
            };
          }
          return el;
        }) : item.condition,
        userResponses: item.userResponses
          ? item.userResponses.map(el => (el && !el.id && !el.userResponses ? [{
            ...el,
            id: shortid.generate(),
          }] : el)) : item.userResponses,
      })),
    }
  ) : {}), [flowResource]);

  useEffect(() => {
    if (!flowsAll?.length) {
      getFlowsAll();
    }
    getOwnCompany();
  }, []);

  return (
    <div className="dialogue-script-block">
      {!selectedNode && (!lastUpdatedGetFlowResource
        || isPendingGetFlowResource
        || !flowResource
        || isPendingGetFlowsAll
        || !flowsAll
      ) ? (
        <LoadingSpinner fullSize />
        ) : (
          <CanvasWidget
            ref={canvasWidgetRef}
            initialValues={initialValues}
            onClickNode={handleClickNode}
            addNewNode={addNewNode}
            nodeRemoved={nodeRemoved}
            deleteNode={deleteNode}
            nodeCloned={nodeCloned}
            onUpdateLink={handleUpdateLink}
            onRemoveLink={handleRemoveLink}
            onUpdateNode={handleUpdateNode}
            readOnlyAlways={readOnlyAlways}
            currentNodeId={currentNodeId}
            initialZoom={initialZoom}
            validators={validators}
            sendQuery={sendQuery}
          />
        )}
      <uikit.Drawer
        visible={!!selectedNode}
        // onClose={selectedNode?.id ? onCloseDrawer : null}
        width={800}
        closable={false}
        destroyOnClose
      >
        {
          selectedNode?.nodeClass === NODE_TYPE.CONDITION ? (
            <EditNodeConditionForm
              onCancel={onCloseDrawer}
              onSubmit={onSubmitEditNodeCondition}
              onDelete={() => openModal('delete')}
              initialValues={selectedNode || {}}
              reportFields={reportFields}
              readOnly={selectedNode?.readOnly || !selectedNode}
              isFirstNode={isFirstActionNode}
            />
          ) : (
            <EditNodeActionForm
              onCancel={onCloseDrawer}
              onSubmit={onSubmitEditNodeAction}
              isFirstNode={isFirstActionNode}
              onDelete={() => openModal('delete')}
              initialValues={selectedNode || {}}
              isPendingGetValidators={isPendingGetValidators}
              lastUpdatedGetValidators={lastUpdatedGetValidators}
              validators={validators}
              flowId={flowId}
              reportFields={reportFields}
              readOnly={selectedNode?.readOnly || !selectedNode}
            />
          )
        }
      </uikit.Drawer>
      {
        isOpenModal === 'delete' && (
          <DeleteWarningModal
            closeModal={closeModal}
            onDelete={onConfirmDelete}
            description={t('TOAST.DELETE_CONFIRM_MAS', { descriptionKey: t('SCENARIOS_CONSTRUCTOR.INSTANCES.STEP') })}
          />
        )
      }
      {
        isOpenModal === 'info' && (
          <InfoModal
            closeModal={closeModal}
            description={t('TOAST.CHATBOT_MODERATION')}
          />
        )
      }
    </div>
  );
};

export default DialogueScriptBlock;

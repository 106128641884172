import React, {
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic } from 'hooks';
import {
  BasicField,
  DropdownReportField,
} from 'components/fields';
import { IconButton } from 'components/buttons';
import plusIcon from 'assets/icons/plus.svg';
import cross from 'assets/icons/cross-dark.svg';

import './style.scss';

const ReportField = ({
  type: externalType,
  onChange: externalOnChange,
  value: externalValue,
  name: externalName,
  input: {
    value = externalValue,
    onChange = externalOnChange,
    type = externalType,
    name = externalName,
  } = {},
  label,
  styles = {},
  options = [[], () => {}],
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [customReportValue, setCustomReportValue] = useState();

  const stylesDropdownField = {
    label: { flex: 3 },
    content: { flex: 7 },
    container: { marginBottom: 12 },
  };
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();
  const fieldRef = useRef();

  const onCustomReportAdd = () => {
    setDropdownOpen();
    onChange(customReportValue);
    if (customReportValue?.trim()
    && (!options[0].length || !options[0].some(el => el.value === customReportValue))) {
      options[1](prevValues => ([...prevValues, {
        label: customReportValue,
        value: customReportValue,
      }]));
      setCustomReportValue();
    }
  };

  return (
    <div
      className="report-field"
      ref={fieldRef}
      style={styles.container}
    >
      <h2 className="report-field__title" style={styles.label}>
        {label}
      </h2>
      <DropdownReportField
        onCustomReportAdd={onCustomReportAdd}
        buttonRef={buttonRef}
        dropdownRef={dropdownRef}
        isDropdownOpen={isDropdownOpen}
        setDropdownOpen={setDropdownOpen}
        toggleDropdown={toggleDropdown}
        name={name}
        type={type}
        label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.REPORT_FIELD')}
        value={value}
        onChange={onChange}
        styles={stylesDropdownField}
        options={options[0]}
        readOnly={readOnly}
        icon={cross}
        customTextField={(
          <div className="report-field__bottom__group">
            <BasicField
              name="customReportField"
              type={type}
              label=" "
              component={BasicField}
              readOnly={readOnly}
              value={customReportValue}
              onChange={setCustomReportValue}
            />
            <IconButton
              filterType="white"
              icon={plusIcon}
              style={{
                position: 'absolute',
                right: 9,
              }}
              onClick={onCustomReportAdd}
              isDisabled={readOnly}
            />
          </div>
          )}
        initialValue="null"
      />
    </div>
  );
};

export default ReportField;

import endpoints from 'const/endpoints';

export default successCallback => ({
  url: endpoints.getCurrentUserUrl(),
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: data => ({
    currentUser: data,
  }),
  update: {
    currentUser: (oldValue, newValue) => newValue,
  },
});
